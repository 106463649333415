import { Text } from "@chakra-ui/react";
import React from "react";
import { biography } from "../../../constants";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import styles from "../about.module.scss";

const Biography = () => {
  return (
    <div className={`${styles.about}`} style={{ height: "100%" }}>
      <div className={styles["section-title"]}>Biography</div>
      {/* <div className={styles.quote}>
        <ImQuotesLeft className={styles["quote-left"]} />
        <Text as="i" className={styles["quote-text"]}>
          {introduction.}
        </Text>
        <ImQuotesRight className={styles["quote-right"]} />
      </div> */}
      <Text className={styles["about-text"]}>{biography.p1}</Text>
      <br />
      <Text className={styles["about-text"]}>{biography.p2}</Text>
      <br />
      <Text className={styles["about-text"]}>{biography.p3}</Text>
    </div>
  );
};

export default Biography;
// ImQuotesLeft
// ImQuotesRight
