import { Text } from "@chakra-ui/react";
import React from "react";
import { introduction } from "../../constants";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import styles from "./about.module.scss";

const AboutIntroduction = () => {
  return (
    <div className={styles.about}>
      <div className={styles["section-title"]}>Introduction</div>
      <div className={styles.quote}>
        <ImQuotesLeft className={styles["quote-left"]} />
        <Text as="i" className={styles["quote-text"]}>
          {introduction.quote}
        </Text>
        <ImQuotesRight className={styles["quote-right"]} />
      </div>
      <Text className={styles["about-text"]}>{introduction.text}</Text>
    </div>
  );
};

export default AboutIntroduction;
// ImQuotesLeft
// ImQuotesRight
