import React from "react";
import styles from "./tak.module.scss";

const Tak = () => {
  return (
    <div className={styles.tak}>
      <h1>Coming Soon ....</h1>
    </div>
  );
};

export default Tak;
