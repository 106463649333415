import { Text } from "@chakra-ui/react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./residency.module.scss";
import og_1 from "../../../assets/Residency/Residency, Oghara /2348121581910_status_19aed3f73c4f4511a8ee176db08ac864.jpg";
import og_2 from "../../../assets/Residency/Residency, Oghara /20210508_130930.jpg";
// import og_3 from "../../../assets/Residency/Residency, Oghara /20210514_131244.jpg";
import og_4 from "../../../assets/Residency/Residency, Oghara /20210508_131339.jpg";
import Slider from "../../../container/slider/Slider";
import { upcyclingResidency } from "../../../constants";
const Residency = () => {
  return (
    <div className={styles.residency}>
      <div className={`section-title`}>Residency</div>

      <div className={styles["exhibition"]}>
        <Slider slides={[og_1, og_2, og_4]} />
        <Text>
          "Waste to Wealth oghara, Delta State", <span>May, 2021</span>
        </Text>
        {/* <Slider slides={[og_1, og_2, og_3, og_4]} /> */}
      </div>
      <div
        className={styles["exhibition"]}
        style={{ flexDirection: "row-reverse" }}
      >
        <Slider slides={[...upcyclingResidency]} />
        <Text>
          "Upcycling Residency", <span>September, 2021</span>
        </Text>
        {/* <Slider slides={[og_1, og_2, og_3, og_4]} /> */}
      </div>
    </div>
  );
};

export default Residency;
