import React from "react";
import Card from "../Cards/Card";
import { images, collection, category } from "../../constants";
import styles from "./worksCategory.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation } from "react-router-dom";

const WorksCategory = () => {
  const byYear = collection.sort((a, b) => a.year - b.year);

  const years = ["2020", "2021", "2022"];
  const location = useLocation();

  return (
    <>
      <div className={styles.worksCategory}>
        <h2 className={styles["section-title"]}>Works (by year) </h2>

        {years.map((year) => (
          <div className={styles.year}>
            <h4 className={styles["year_title"]}>{year}</h4>

            <div
              className={`${styles.galleryContainer} ${styles["year_items"]}`}
            >
              {byYear
                .filter((item) => item.year === year)
                .map((item, i) => (
                  //  <Card img={item.img} title={item.title} />
                  <GalleryContainer
                    img={item.img.length > 1 ? item.img[1] : item.img[0]}
                    title={item.title}
                    index={i}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export function GalleryContainer(props) {
  return (
    <div className={styles.galleryItem}>
      <div className={styles.image}>
        <img loading="lazy" src={props.img} alt="" />
        {/* <LazyLoadImage
          placeholder={<div />}
          effect="blur"
          src={props.img}
          alt=""
          visibleByDefault={false}
        /> */}
      </div>
      <div className={styles.text}>{props.title}</div>
    </div>
  );
}

export default WorksCategory;
