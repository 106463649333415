import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Box,
  useDisclosure,
  usePopper,
  useOutsideClick,
  useMergeRefs,
  Stack,
  StackDivider,
  VStack,
  Button,
  ButtonGroup,
  LinkBox,
  Text,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function PopOver() {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Popover
        placement="bottom"
        closeOnBlur={true}
        left="0"
        right="0"
        className="pop-over"
      >
        <PopoverTrigger>
          <Text cursor={"pointer"}>WORKS</Text>
        </PopoverTrigger>
        <PopoverContent
          rounded={"md"}
          border="20px"
          borderColor={"gray.500"}
          boxShadow="dark-lg"
          bg="#e5e5e5"
        >
          <PopoverBody py={20} rounded={"lg"}>
            <Stack
              p={10}
              fontWeight={"medium"}
              letterSpacing="2px"
              w="100%"
              height={"100%"}
              divider={<StackDivider borderColor="gray.500" />}
            >
              <Link to="/works">
                <Box>
                  <Text cursor={"pointer"} pb={"10px"}>
                    Collections
                  </Text>
                </Box>
              </Link>
              <Link to="/works/name">
                <Box>
                  <Text cursor={"pointer"} pb={"10px"}>
                    Name
                  </Text>
                </Box>
              </Link>
              <Link to="/works/year">
                <Box>
                  <Text cursor={"pointer"} pb={"10px"}>
                    Year
                  </Text>
                </Box>
              </Link>
            </Stack>
          </PopoverBody>
        </PopoverContent>
        {/* {isOpen && <PopOver onToggle={onToggle} />} */}
      </Popover>
    </>
  );
}

export default PopOver;
