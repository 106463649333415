/* eslint-disable import/no-anonymous-default-export */
import Herald from "../assets/3 Musketeers/Herald2021.JPG";
import Herald_2 from "../assets/3 Musketeers/Herald22021.JPG";
import johnHop from "../assets/3 Musketeers/JohnHop 2021.JPG";
import johnHop_2 from "../assets/3 Musketeers/Johnhop2 2021.JPG";
import pawSitive_2 from "../assets/3 Musketeers/Paw-sitive22021.JPG";
import pawSitive from "../assets/3 Musketeers/Paw-sitive2021.JPG";

// carpe diem
import humminglad from "../assets/Carpe diem/Humminglad22021.PNG";
import humminglad2 from "../assets/Carpe diem/humminglad2021.JPG";
import jehriemouse from "../assets/Carpe diem/Jehrie mouse 2020.JPG";
import jerrythemouse from "../assets/Carpe diem/jerry the mouse 2020.jpg";
import jerrythemouse2 from "../assets/Carpe diem/jerry the mouse 2 2020.jpg";
import magniFishcent from "../assets/Carpe diem/Magni-Fish-cent-II-2020.JPG";
import magniFishcent2 from "../assets/Carpe diem//Magni-Fish-cent-IIb-2020.JPG";

// Motherhood

import Cheek_hen from "../assets/Motherhood/Cheek-hen 2020.jpg";
import Cheek_hen3 from "../assets/Motherhood/Cheek-hen3 2020.JPG";
import Cheek_hen4 from "../assets/Motherhood/Cheek-Hen 4 2020.JPG";
import omidan from "../assets/Motherhood/Omidan 2019.JPG";
import omidanII from "../assets/Motherhood/Omidan II 2019.JPG";
import omidanIII from "../assets/Motherhood/Omidan III 2019.JPG";

// Outlook on life
import Heatmaker from "../assets/Outlook on life/Heat maker 2021.JPG";
import High_hopes_heron from "../assets/Outlook on life/High hopes heron 2021.JPG";
import High_hopes_heron2 from "../assets/Outlook on life/High hopes heron 2 2021.JPG";
import hypnotic_stare from "../assets/Outlook on life/hypnotic stare 2022.JPG";
import Ratrace from "../assets/Outlook on life/Rat race 2021.PNG";

// Owl series
import _1owlClock from "../assets/Owl series/1 owl clock 2020.jpg";
import _1owlClockb from "../assets/Owl series/1 owl clockb 2020.JPG";
import owlClock from "../assets/Owl series/2 Owl Clock 2022.JPG";
import owlClock2 from "../assets/Owl series/2 Owl Clock 2 2022.JPG";
import owl_some from "../assets/Owl series/Owl-some 2021.PNG";

// Residency
import res1 from "../assets/Residency/Upcycling residency/IMG_3691.jpg";
import res2 from "../assets/Residency/Upcycling residency/IMG_4092.JPG";
import res3 from "../assets/Residency/Upcycling residency/IMG_4113.JPG";
import res4 from "../assets/Residency/Upcycling residency/IMG_4656.JPG";
import res5 from "../assets/Residency/Upcycling residency/IMG_4658.JPG";
import res6 from "../assets/Residency/Upcycling residency/IMG_4659.JPG";
import res7 from "../assets/Residency/Upcycling residency//IMG_4818.JPG";
import res8 from "../assets/Residency/Upcycling residency/IMG_4819.JPG";
import res9 from "../assets/Residency/Upcycling residency/IMG_4820.JPG";
import res0 from "../assets/Residency/Upcycling residency/IMG_4821.JPG";

// Road back to you
import deer from "../assets/Road back to you/deer 2021.jpg";
import deerb from "../assets/Road back to you/deerb 2021.jpg";
import deer3 from "../assets/Road back to you/deer3 2021.jpg";
import identity from "../assets/Road back to you/Identity 2021.JPG";
import identityb from "../assets/Road back to you/Identity b 2021.JPG";
import roe_2d_deer from "../assets/Road back to you/Roe-2n-deer 2021.PNG";
import roe_2d_deerb from "../assets/Road back to you/Roe-2n-deerb 2021.PNG";
import sync_mastee2 from "../assets/Road back to you/Sync master 2 2021.JPG";
import sync_mastee3 from "../assets/Road back to you/Sync master3 2021.JPG";
import sync_mastee4 from "../assets/Road back to you/Sync mastee 4 2021.JPG";

// Others
import brakeFly from "../assets/others/brake fly 2019.JPG";
import courageTheCowardly from "../assets/others/courage the cowardly dog. 2019 JPG.JPG";
import courageTheCowardly2 from "../assets/others/courage the cowardly dog.2 2019.JPG";
import courageTheCowardly3 from "../assets/others/courage the cowardly dog.3. 2019 JPG.JPG";
import eclipse from "../assets/others/eclipse2021.PNG";
import fastNfurious from "../assets/others/fast N Fur-ious.jpg";
import fastNfurious4 from "../assets/others/Fast N fur-rious 4.jpg";
import harePotter from "../assets/others/Hare Potter2020.jpg";
import hoofHearted from "../assets/others/hoof hearted.jpg";
import hoofHearted3 from "../assets/others/Hoof hearted 3.jpg";
import hoofHearted2 from "../assets/others/hoof heated 2.png";
import jackSparrow from "../assets/others/Jacksaprrow2021.png";
import jackSparrow2 from "../assets/others/Jacksparrow2021.png";
import jumpMaestro from "../assets/others/Jump Maestro 2020.JPG";
import jumpMaestro2 from "../assets/others/Jump maestro 2 2020.JPG";
import ladyBug from "../assets/others/lady bug2021.JPG";
import madnifishcent from "../assets/others/Madnifishcent Ic 2019.JPG";
import mafasa from "../assets/others/Mafasa2021.JPG";
import magniFishcent_I from "../assets/others/Magni-Fish-Cent I 2019.JPG";
import magniFishcent_IIIa from "../assets/others/Magnifishcent IIIa 2021.PNG";
import magniFishcent_IIIb from "../assets/others/Magnifishcent IIIb2021.PNG";
import mufasaII from "../assets/others/Mufasa II 2021.JPG";
import mufasaIII from "../assets/others/Mufasa III 2021.JPG";
import quailBird from "../assets/others/Quail bird 2021.jpg";
import quailBird2 from "../assets/others/Quail birl 2021.PNG";
import samba from "../assets/others/samba 2022.PNG";
import samba2 from "../assets/others/samba2 2022.PNG";
import samba3 from "../assets/others/samba 3. 2022 PNG.PNG";
import snoopDawg from "../assets/others/Snoop dawg 2022. JPG.JPG";
import snoopDawg2 from "../assets/others/Snoop dawg 2 2022.JPG";
import snoopDawg3 from "../assets/others/Snoop dawg 3. 2022JPG.JPG";
import snoopDawg4 from "../assets/others/Snoop dawg 4 2022.JPG";
import snoopDawg5 from "../assets/others/Snoop dawg 5 2022.JPG";
import voiceOfVoiceless from "../assets/others/Voice of Voiceless 2020.jpg";

// Exhibitions
import afr_1 from "../assets/Exhibitions/Africa Day Concert/image_6483441 (1).JPG";
import afr_2 from "../assets/Exhibitions/Africa Day Concert/image_6483441 (2).JPG";
import afr_3 from "../assets/Exhibitions/Africa Day Concert/image_6483441.JPG";

// mini
import min_1 from "../assets/Exhibitions/Mini Art Fare/image_6487327 (1).JPG";
import min_2 from "../assets/Exhibitions/Mini Art Fare/image_6487327 (3).JPG";
import min_3 from "../assets/Exhibitions/Mini Art Fare/image_6487327.JPG";

// limcaf
// import lim_1 from "../assets/Exhibitions/Limcaf/IMG_6597.JPG";
import lim_2 from "../assets/Exhibitions/Limcaf/IMG_2832.jpg";
import lim_3 from "../assets/Exhibitions/Limcaf/Screenshot 2022-08-21 at 18.53.05.png";
import lim_4 from "../assets/Exhibitions/Limcaf/Screenshot 2022-08-21 at 18.53.52.png";
import lim_5 from "../assets/Exhibitions/Limcaf/Screenshot 2022-08-21 at 18.54.21.png";

// upcycling
import up_1 from "../assets/Exhibitions/Upcycling Exhibition/IMG_4403.jpg";
import up_2 from "../assets/Exhibitions/Upcycling Exhibition/IMG_4405.jpg";
import up_3 from "../assets/Exhibitions/Upcycling Exhibition/IMG_4407.jpg";
import up_4 from "../assets/Exhibitions/Upcycling Exhibition/IMG_4408.jpg";
import up_5 from "../assets/Exhibitions/Upcycling Exhibition/IMG_4409.jpg";
import up_6 from "../assets/Exhibitions/Upcycling Exhibition/IMG_8729.JPG";

const upcycling = {
  images: [up_1, up_2, up_3, up_4, up_5, up_6],
  title: "Upcycling Redefined",
};
const limcaf = {
  images: [lim_2, lim_5, lim_4, lim_3],
  title: "Life In My City Art Festival Exhibition",
};
const africaDay = {
  images: [afr_3, afr_1, afr_2],
  title: "Africa Day Concert",
};
const miniArt = {
  images: [min_1, min_2, min_3],
  title: "Mini Art Fare",
};

export default {
  Herald,
  Herald_2,
  johnHop,
  johnHop_2,
  pawSitive_2,
  pawSitive,
  humminglad,
  humminglad2,
  jehriemouse,
  jerrythemouse,
  jerrythemouse2,
  magniFishcent,
  magniFishcent2,
  Cheek_hen,
  Cheek_hen3,
  Cheek_hen4,
  omidan,
  omidanII,
  omidanIII,
  Heatmaker,
  High_hopes_heron,
  High_hopes_heron2,
  hypnotic_stare,
  Ratrace,
  _1owlClock,
  _1owlClockb,
  owlClock,
  owlClock2,
  owl_some,
  deer,
  deerb,
  deer3,
  identity,
  identityb,
  roe_2d_deer,
  roe_2d_deerb,
  sync_mastee2,
  sync_mastee3,
  sync_mastee4,
  brakeFly,
  courageTheCowardly,
  courageTheCowardly2,
  courageTheCowardly3,
  eclipse,
  fastNfurious,
  fastNfurious4,
  harePotter,
  hoofHearted,
  hoofHearted3,
  jackSparrow,
  hoofHearted2,
  jackSparrow2,
  jumpMaestro,
  jumpMaestro2,
  ladyBug,
  madnifishcent,
  mafasa,
  magniFishcent_I,
  magniFishcent_IIIa,
  magniFishcent_IIIb,
  mufasaII,
  mufasaIII,
  quailBird,
  quailBird2,
  samba,
  samba2,
  samba3,
  snoopDawg,
  snoopDawg2,
  snoopDawg3,
  snoopDawg4,
  snoopDawg5,
  voiceOfVoiceless,
  res1,
  res2,
  res3,
  res4,
  res5,
  res6,
  res7,
  res8,
  res9,
  res0,
  africaDay,
  miniArt,
  limcaf,
  upcycling,
};
