import React from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import ex_1 from "../../assets/Exhibitions/Upcycling Exhibition/IMG_4409.jpg";
// import ex_2 from "../../assets/Exhibitions/Limcaf/IMG_6597.JPG";
import { Text } from "@chakra-ui/react";
import { africaDay, miniArtDay, limcaf, upcycling } from "../../constants";
import Slider from "../../container/slider/Slider";

import styles from "./exhibition.module.scss";

const Exhibition = () => {
  return (
    <div>
      <div className={`section-title`}>Exhibition</div>
      <div className={styles.grid}>
        <div className={styles["exhibition"]}>
          <Slider slides={[...upcycling.images]} />

          {/* <LazyLoadImage src={ex_1} width={"300px"} /> */}
          <Text>
            "Upcycling Redefined", <span>Abuja, September 21, 2021</span>
          </Text>
        </div>
        <div className={styles["exhibition"]}>
          <Slider slides={[...limcaf.images]} />
          {/* <LazyLoadImage src={ex_2} width={"300px"} /> */}
          <Text textAlign={"center"}>
            "Life In My City Art Festival Exhibition",{" "}
            <span>Enugu,November 20,2021</span>
          </Text>
        </div>

        <div className={styles["exhibition"]}>
          <Slider slides={[...africaDay.images]} />
          {/* <LazyLoadImage src={africaDay.images[2]} width={"300px"} /> */}
          <Text>
            "{africaDay.title}", <span>May 25, 2021</span>
          </Text>
        </div>

        <div className={styles["exhibition"]}>
          <Slider slides={[...miniArtDay.images]} />
          {/* <LazyLoadImage src={miniArtDay.images[1]} width={"300px"} /> */}
          <Text>
            "{miniArtDay.title}" <span>Lagos, December 7, 2021</span>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Exhibition;
