import React from "react";
import styles from "./outing.module.scss";

const Outings = () => {
  return (
    <div className={styles.outings}>
      <h3 className="section-title">PRESS</h3>
      <div>
        <p>“Waste to Wealth”, Interview, Channels TV, May 16, 2021</p>
        <p>“Upcycling Redefined”, Interview, NTA, September 18, 2021</p>
        <p>“Upcycling Redefined”, Interview, Channels TV, September 18, 2021</p>
      </div>
    </div>
  );
};

export default Outings;
