import React from "react";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles["footer-brand"]}>
        <p>&copy;</p>
        <p>Ayodeji kingsley</p>
        {/* <div className={styles.footerImg}>
          <img src={logo} alt="" />
        </div> */}
      </div>
      <div className={styles["social-icons"]}>
        <Link to="">
          <FaTwitter />
        </Link>
        <Link to="">
          <FaLinkedinIn />
        </Link>
        <a href="https://www.instagram.com/theayodejikingsley/">
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
