import React, { lazy, Suspense, useEffect } from "react";
import "./App.scss";
import Nav from "./components/Nav/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Works from "./components/Works/Works";
import Exhibition from "./components/Exhibition/Exhibition";
import About from "./components/About/AboutIntroduction";
import Category from "./components/Category/Category";
// import Aos from "aos";
// import "aos/dist/aos.css";
import WorksCategory from "./components/categoriesbydate/WorksCategory";
import WorksByAlphabet from "./components/categoriesbydate/WorksByAlphabet";
import AboutIntroduction from "./components/About/AboutIntroduction";
import Footer from "./components/Footer/Footer";
import Residency from "./components/Exhibition/Residency/Residency";
import Biography from "./components/About/Biography/Biography";
import Tak from "./TAK/Tak";
import Outings from "./components/Exhibition/Outings/Outings";

const LazyWorksCategory = lazy(() =>
  import("./components/categoriesbydate/WorksCategory")
);
const App = () => {
  return (
    <>
      <Suspense fallback="...loading">
        <BrowserRouter>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/works" element={<Works />} />

            <Route path="/works/collection" element={<Works />} />
            <Route path="/works/name" element={<WorksByAlphabet />} />
            {/* <Route path="/works/year" element={<WorksCategory />} /> */}
            <Route path="/works/year" element={<LazyWorksCategory />} />
            <Route
              path="/works/:category"
              element={
                <Suspense>
                  <Category />
                </Suspense>
              }
            />
            <Route path="/exhibition" element={<Exhibition />} />
            <Route path="/exhibition/residency" element={<Residency />} />
            <Route path="/exhibition/outings" element={<Outings />} />
            <Route path="/about" element={<AboutIntroduction />} />
            <Route path="/about/biography" element={<Biography />} />
            <Route path="/tak" element={<Tak />} />
            <Route path="*" element={<h2>404 Not Found</h2>} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
