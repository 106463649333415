import React, { useEffect } from "react";
import Card from "../Cards/Card";
import { category } from "../../constants";
import "./works.scss";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const Works = () => {
  let navigate = useNavigate();

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  return (
    <>
      <div className="works">
        <div className="works_title">
          <h2>Works</h2>
          <div className="line"></div>
        </div>

        <div
          className="works_container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {category.map((item) => (
            <Card
              img={item.img}
              title={item.title}
              // desc={collection[7].description}
              onClick={() => {
                navigate(`${item.title.toLowerCase().replace(/ /g, "")}`);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Works;
