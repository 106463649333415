import React from "react";
import style from "./card.module.scss";

const Card = (props) => {
  return (
    <>
      <div className={style.card} onClick={props.onClick}>
        <img src={props.img} alt={props.title} className={style.card_bg} />
        <div className={style.card_overlay}></div>

        <div className={style.card_text}>
          <div className={style.card_text_title}>{props?.title}</div>
          {props.title && <div className={style.card_text_title__line}></div>}
          {props.year ||
            (props.desc && (
              <div className={style.card_text_desc}>{props.desc}</div>
            ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(Card);
