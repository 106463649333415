import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import homeImg from "../../assets/nee2.png";
const Home = () => {
  return (
    <>
      <div className="home">
        <div className="home_text">
          {/* <h2 className="home_text__header">Explore works</h2>
          <p className="home_text__body">
            Take a look and Explore Ayodeji Kingsley's exclusive and creative
            metal art works
          </p> */}
          <div className="heroImg">
            <img src={homeImg} alt="ayodeji kingsley visual artist" />
          </div>
          <div className="buttons">
            <Link to="/works">
              <button className="explore_btn">Explore</button>
            </Link>
            <Link to="/tak">
              <button className="explore_btn" disabled>
                Buy Art
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
