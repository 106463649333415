import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Card from "../Cards/Card";
import { category, collection, images } from "../../constants";
import classes from "./section.module.scss";

export const Section = ({ image, desc, title }) => {
  return (
    <div className={classes.Section}>
      <div className={classes.desc}>
        <h2>{title}</h2>
        <p> {desc}</p>
      </div>

      <div className={classes.img} data-aos="zoom" data-aos-duration="1000">
        <img src={image} alt="" data-aos="fade-left" />
      </div>
    </div>
  );
};

const Category = () => {
  let { category } = useParams();

  const list = useMemo(
    () =>
      collection
        .filter((item) => {
          // console.log(collection.length, "first");
          return item.category.toLowerCase().replace(/ /g, "") === category;
        })
        .map((item) => (
          <Section
            key={item.title}
            desc={item.description}
            title={item.title}
            image={item.img.length > 1 ? item.img[1] : item.img[0]}
          />
        )),
    [category]
  );

  // <Card img={item.img[0]} />

  return (
    <>
      <div style={{ color: "#fff", paddingTop: "4rem" }} className="category">
        {/* <Section desc={collection[0].description} title={collection[0].title} /> */}
        {list}
      </div>
    </>
  );
};

export default Category;
