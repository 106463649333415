import images from "./images";
import { collection, category, introduction, biography } from "./Data";

//
const upcyclingResidency = [
  images.res0,
  images.res1,
  images.res2,
  images.res3,
  images.res4,
  images.res5,
  images.res6,
  images.res7,
  images.res8,
  images.res9,
];

const africaDay = images.africaDay;
const miniArtDay = images.miniArt;
const limcaf = images.limcaf;
const upcycling = images.upcycling;

export {
  // images,\
  africaDay,
  miniArtDay,
  limcaf,
  upcycling,
  collection,
  category,
  introduction,
  upcyclingResidency,
  biography,
};
