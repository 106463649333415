import React from "react";
import Card from "../Cards/Card";
import { images, collection, category } from "../../constants";
import styles from "./worksCategory.module.scss";
import { GalleryContainer } from "./WorksCategory";

const WorksByAlphabet = () => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));

  const byAlphabet = collection.sort((a, b) => a.year - b.year);

  return (
    <>
      <div className={styles.worksCategory}>
        <h2 className={styles["section-title"]}>ALL WORKS </h2>

        {alphabet.map((letter) => (
          <div className={styles.year}>
            <h4 className={styles["year_title"]}>{letter}</h4>

            <div
              className={`${styles.galleryContainer} ${styles["year_items"]}`}
            >
              {collection
                .filter((item) => {
                  return item.title.charAt(0) === letter;
                })
                .map((item, i) => {
                  //  <Card img={item.img} title={item.title} />
                  //  if (Object.entries(item).length === 0) return;

                  return (
                    <GalleryContainer
                      img={item.img.length > 1 ? item.img[1] : item.img[0]}
                      title={item.title}
                      index={i}
                    />
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WorksByAlphabet;
