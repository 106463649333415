import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./nav.module.scss";
import { FiMenu, FiX } from "react-icons/fi";

import logo from "../../assets/Personal/ayodeji Kingsley trademark2.png";
import logo2 from "../../assets/Personal/high resolution.png";
// import logo from "../../assets/Personal/ayodeji Kingsley trademark.png";
// import logo from "../../assets/Personal/tk2.png";
import useIntersecctionObserver from "../../hooks/use-intersectionObserver";
import { Image, Text } from "@chakra-ui/react";
import PopOver from "./PopOver";
// import {Animate}

const Nav = () => {
  const [click, setClick] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showWorksDropdown, setShowWorksDropdown] = useState(false);
  const location = useLocation();

  const img = location.pathname === "/tak" ? logo2 : logo;

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= "800") {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", showButton);
    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  return (
    <>
      <header className="sticky">
        <div className={`${styles.navbar}`}>
          <div className={styles.navbarContainer}>
            <div className={`${styles.navbar_brand}`}>
              <Link to="/">
                <img src={img} alt="theayodejikingsley-logo" />
              </Link>
            </div>
            <div className={styles["menu-icon"]} onClick={handleClick}>
              {click ? <FiX /> : <FiMenu />}
            </div>

            <ul
              className={
                click
                  ? `${styles.sidebar} ${styles.active} `
                  : `${styles.sidebar}`
              }
            >
              <li className={styles["nav-item"]} onClick={closeMobileMenu}>
                <NavLink
                  className={({ isActive }) => (isActive ? ` active` : "")}
                  to="/"
                >
                  HOME
                </NavLink>
              </li>
              <li className={styles["nav-item"]} onClick={closeMobileMenu}>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/tak"
                >
                  TAK
                </NavLink>
              </li>
              <li
                className={styles["nav-item"]}
                onMouseEnter={() => setShowWorksDropdown(true)}
                onMouseLeave={() => setShowWorksDropdown(false)}
              >
                {/* <PopOver /> */}
                <Text to="works">Works</Text>
                {showWorksDropdown && (
                  <ul
                    className={styles["sub-menu"]}
                    onClick={() => {
                      closeMobileMenu();
                      setShowWorksDropdown(false);
                    }}
                  >
                    <li className="">
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/works"
                      >
                        Collections
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/works/name"
                      >
                        Name
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/works/year"
                      >
                        Year
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li
                className={styles["nav-item"]}
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
              >
                <Text to="/">Exhibitions</Text>
                {showDropdown && (
                  <ul
                    className={styles["sub-menu"]}
                    onClick={() => {
                      closeMobileMenu();
                      setShowDropdown(false);
                    }}
                  >
                    <li className="">
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/exhibition"
                      >
                        Group
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/exhibition/Residency"
                      >
                        Residency
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/exhibition/Outings"
                      >
                        Outings
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li
                className={styles["nav-item"]}
                onMouseEnter={() => setShowAboutDropdown(true)}
                onMouseLeave={() => setShowAboutDropdown(false)}
              >
                <Text to="about">About</Text>

                {showAboutDropdown && (
                  <ul
                    className={styles["sub-menu"]}
                    onClick={() => {
                      closeMobileMenu();
                      setShowAboutDropdown(false);
                    }}
                  >
                    <li className="">
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/about"
                      >
                        Introduction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/about/biography"
                      >
                        Biography
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
            {/* </nav> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Nav;
// Home | works | exhibitions | about
// font-family: ProximaNovaRegular, sans-serif;
