import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./slider.scss";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function App({ slides }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const [finishedLoading, setFinishedLoading] = useState(false);

  const handleSwipe = (e) => {
    const swiper = e;
    setTimeout(() => {
      setThumbsSwiper(swiper);
    }, 0);
  };

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={5}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {slides.map((img) => (
          <SwiperSlide>
            {/* <img
              //   data-src="https://swiperjs.com/demos/images/nature-8.jpg"
              data-src={img}
              className="swiper-lazy"
               alt=""
               src={img}
             /> */}
            <LazyLoadImage src={img} />
            {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div> */}
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        loop={true}
        spaceBetween={10}
        slidesPerView={3}
        freeMode={true}
        onSwiper={handleSwipe}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {slides.map((img) => (
          <SwiperSlide>
            {/* <img
              //   data-src="https://swiperjs.com/demos/images/nature-8.jpg"
              data-src={img}
              className="swiper-lazy"
               alt=""
               src={img}
             /> */}
            <LazyLoadImage src={img} />
            {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
